<template>
    <div v-if="loading" class="text-center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
    </div>
    <div v-else-if="error" class="text-center">
        {{ error }}
    </div>
    <div v-else>
        <v-card>
            <v-card-title class="accent white--text">
                {{ $t('interface.correlation.title') }}
            </v-card-title>
            <v-card-text style="padding: 0px;">
                <div v-if="currentCompares.length > 0 || currentFilters.length > 0 || currentBenchmarks.length > 0">
                    <v-alert :value="true" type="warning">
                        {{ $t('interface.filters_compares_benchmarks_not_supported') }}
                    </v-alert>
                </div>
                <v-simple-table class="bordered correlation">
                    <thead>
                        <tr>
                            <td class="border-right no-border-left no-border-top"></td>
                            <td v-if="showRisks" class="text-center border-right" colspan="7">Risico's</td>
                            <td v-if="showSignals" class="text-center" colspan="4">Bevlogenheid</td>
                        </tr>
                        <tr>
                            <th class="border-right text-title">{{ content.title }}</th>
                            <template v-if="showRisks && data.risks">
                                <th class="text-vertical"><span>Mentaal uitgeput</span></th>
                                <th class="text-vertical"><span>Ontspanning</span></th>
                                <th class="text-vertical"><span>Piekeren</span></th>
                                <th class="text-vertical"><span>Spanning door werk</span></th>
                                <th class="text-vertical"><span>Spanning door collega's</span></th>
                                <th class="text-vertical"><span>Spanning prive</span></th>
                                <th class="text-vertical border-right"><span>Werkdruk</span></th>
                            </template>
                            <template v-if="showSignals && data.signals">
                                <th class="text-vertical"><span>Energie</span></th>
                                <th class="text-vertical"><span>Passie</span></th>
                                <th class="text-vertical"><span>Flow</span></th>
                                <th class="text-vertical"><span>Geluk</span></th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="questionCode in questionCodes" :key="questionCode">
                            <td class="border-right" :class="toggleHighlight(questionCode)">
                                <span v-if="data.risks[questionCode] && data.risks[questionCode].question" >
                                    {{ templateQuestion(data.risks[questionCode]['question'], $store.getters.reportSettings) }}
                                </span>
                                <span v-else-if="data.signals[questionCode] && data.signals[questionCode].question" >
                                    {{ templateQuestion(data.signals[questionCode]['question'], $store.getters.reportSettings) }}
                                </span>
                                <span v-else>
                                    <i>{{ questionCode }} has no question translation available.</i>
                                </span>
                            </td>

                            <!-- Then show the correlations -->
                            <template v-if="showRisks && data.risks && data.risks[questionCode] !== undefined">
                                <td :class="highlightCorrelation(data.risks[questionCode]['Mentaal uitgeput'])" class="text-center">{{ highlightValue(data.risks[questionCode]['Mentaal uitgeput']) }}</td>
                                <td :class="highlightCorrelation(data.risks[questionCode]['Ontspanning'])" class="text-center">{{ highlightValue(data.risks[questionCode]['Ontspanning']) }}</td>
                                <td :class="highlightCorrelation(data.risks[questionCode]['Piekeren'])" class="text-center">{{ highlightValue(data.risks[questionCode]['Piekeren']) }}</td>
                                <td :class="highlightCorrelation(data.risks[questionCode]['Spanning door werk'])" class="text-center">{{ highlightValue(data.risks[questionCode]['Spanning door werk']) }}</td>
                                <td :class="highlightCorrelation(data.risks[questionCode]['Spanning door collega\'s'])" class="text-center">{{ highlightValue(data.risks[questionCode]['Spanning door collega\'s']) }}</td>
                                <td :class="highlightCorrelation(data.risks[questionCode]['Spanning prive'])" class="text-center">{{ highlightValue(data.risks[questionCode]['Spanning prive']) }}</td>
                                <td :class="highlightCorrelation(data.risks[questionCode]['Werkdruk'])" class="text-center border-right">{{ highlightValue(data.risks[questionCode]['Werkdruk']) }}</td>
                            </template>
                            <template v-if="showSignals && data.signals && data.signals[questionCode] !== undefined">
                                <td :class="highlightCorrelation(data.signals[questionCode]['Energie'])" class="text-center">{{ highlightValue(data.signals[questionCode]['Energie']) }}</td>
                                <td :class="highlightCorrelation(data.signals[questionCode]['Passie'])" class="text-center">{{ highlightValue(data.signals[questionCode]['Passie']) }}</td>
                                <td :class="highlightCorrelation(data.signals[questionCode]['Flow'])" class="text-center">{{ highlightValue(data.signals[questionCode]['Flow']) }}</td>
                                <td :class="highlightCorrelation(data.signals[questionCode]['Geluk'])" class="text-center">{{ highlightValue(data.signals[questionCode]['Geluk']) }}</td>
                            </template>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'

export default {
    name: "Correlation",
    mixins: [NolostInsights],
    props: ['content'],
    data() {
        return {
            error: false,
            loading: true,
            data: [],
            showRisks: 1,
            showSignals: 1,
            defaultQuestionCodes: [
                // "bo_verwachtingen",
                // "bo_goed",
                // "bo_middelen",
                // "bo_contacten",
                // "bo_motivatiecollegas",
                // "bo_leuk",
                // "bo_belangrijkwerk",
                // "bo_betrokkenleiding",
                // "bo_groeien",
                // "bo_mening",
                // "bo_ontwikkeling",
                // "bo_erkenning",
                // "bo_vooruitgang",
                // "bo_successen",

                // New questions....
                "mgo_betekenisvolwerk_goed",
                "mgo_betekenisvolwerk_leuk",
                "mgo_betekenisvolwerk_successen",
                "mgo_betekenisvolwerk_erkenning",
                "mgo_betekenisvolwerk_belangrijkwerk",
                "mgo_betekenisvolwerk_verwachtingen",
                "mgo_betekenisvolwerk_achtermissie",
                "mgo_veiligwerkklimaat_klaarstaan",
                "mgo_veiligwerkklimaat_eerlijkemening",
                "mgo_veiligwerkklimaat_werkcollegas",
                "mgo_veiligwerkklimaat_fouten",
                "mgo_veiligwerkklimaat_verbinding",
                "mgo_veiligwerkklimaat_mijzelfzijn",
                "mgo_empathischleiderschap_betrokkenbijmens",
                "mgo_empathischleiderschap_tijdvrijmaken",
                "mgo_empathischleiderschap_motivatie",
                "mgo_empathischleiderschap_openfeedback",
                "mgo_empathischleiderschap_veiligheid",
                "mgo_empathischleiderschap_priveleven",
                "mgo_doelgerichtleiderschap_feedback",
                "mgo_doelgerichtleiderschap_doelenstellen",
                "mgo_doelgerichtleiderschap_inspiratie",
                "mgo_persoonlijkeregelruimte_middelen",
                "mgo_persoonlijkeregelruimte_welzijn",
                "mgo_persoonlijkeregelruimte_autonomie",
                "mgo_persoonlijkeregelruimte_werkprivebalans",
                "mgo_persoonlijkeregelruimte_regelmogelijkheden",
                "mgo_ontwikkelingengroei_groeien",
                "mgo_ontwikkelingengroei_doorgroeien",
                "mgo_ontwikkelingengroei_investering",
                "mgo_ontwikkelingengroei_ontwikkeling"
            ],
            questionCodes: []
        }
    },
    computed: {
        currentCompares () { return this.$store.state.currentCompares },
        currentFilters () { return this.$store.state.currentFilters },
        currentBenchmarks () { return this.$store.state.currentBenchmarks },
    },
    methods: {
        getData() {
            // Question codes to make the correlation with
            this.questionCodes = this.content.question_codes || this.defaultQuestionCodes
            if (this.content.show_risks === false) { this.showRisks = 0 }
            if (this.content.showSignals === false) { this.showSignals = 0 }
            this.loading = true;
            this.axios.get(process.env.VUE_APP_API_URL + '/insights/correlation/?rows=' + this.questionCodes.join(",") + "&show_risks=" + this.showRisks + "&show_signals=" + this.showSignals)
            .then(resp => {
                this.data = resp.data.data
                this.loading = false;
            })
            .catch(err => {
                this.error = err
                this.questions = []
                this.loading = false;
            })
        },
        // If a row is configured as a highlight, add the class 'row-highlight' to it.
        toggleHighlight(row) {
            if (this.content.row_highlights) {
                return this.content.row_highlights.includes(row) ? "row-highlight" : ""
            }
        },
        // 
        highlightValue(val) {
            if ((this.content.settings && this.content.settings.highBoundary && (Math.abs(val) > this.content.settings.highBoundary)) || Math.abs(val) > 0.4) {
                return "●"
            } else if ((this.content.settings && this.content.settings.lowBoundary && (Math.abs(val) > this.content.settings.lowBoundary)) || Math.abs(val) > 0.2) {
                return "●"
            } else {
                return ""
            }
        },
        // Highlight a correlation vale if it is higher then the higher or lower boundary configured 
        highlightCorrelation(val) {
            if ((this.content.settings && this.content.settings.highBoundary && (Math.abs(val) > this.content.settings.highBoundary)) || Math.abs(val) > 0.4) {
                return "highlight-high"
            } else if ((this.content.settings && this.content.settings.lowBoundary && (Math.abs(val) > this.content.settings.lowBoundary)) || Math.abs(val) > 0.2) {
                return "highlight-low"
            } else {
                return ""
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
    th{
        /* background-color: #009BD9; */
        color: white;
    }
    tr th.text-title{
        color: white!important;
        font-size: 3rem
    }
    tr th.text-vertical{
        /* color: white!important; */
        width: 30px;
    }
    tr th.text-vertical span{
        writing-mode: vertical-rl;
        display: block;
        text-align: center;
        font-weight: normal;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    td.row-highlight{
        background-color: #F8BFCE;
    }
    td.highlight-low{
        color: #83929B
    }
    td.highlight-high{
        background-color: #00A583;
        color: white
    }
    .no-border-left{border-left: 0px} 
    .no-border-top{border-top: 0px}
    .bordered table{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .border-right{
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }
</style>