<template>
    <div v-if="loading" class="text-center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
    </div>
    <div v-else-if="error" class="text-center">
        {{ error }}
    </div>
    <div v-else>
        <v-card>
            <v-card-title class="accent white--text">
                {{ $t('interface.matrix.title') }}
                <v-spacer />
                <span class="text-body-2" v-if="total_count && total_count > $store.getters.reportSettings.min_results">{{ $tc('interface.participant_count') }}: {{ total_count }}</span>
            </v-card-title>
            <v-card-text style="padding: 0px;">
                <div v-if="currentCompares.length > 0 || currentFilters.length > 0 || currentBenchmarks.length > 0">
                    <v-alert :value="true" type="warning">
                        {{ $t('interface.filters_compares_benchmarks_not_supported') }}
                    </v-alert>
                </div>
                <div>
                    <table class="table table-matrix">
                        <tr>
                            <td class="square">
                                {{ (data.percentages['risk_and_engagement'] * 100).toFixed(0) }}%
                                <span class="counts">{{ $tc('interface.participants', data.counts.risk_and_engagement) }}</span>
                            </td>
                            <td class="matrix-outline text-vertical-top">&larr; Zeer Bevlogen</td>
                            <td class="square">
                                {{ (data.percentages['engagement'] * 100).toFixed(0) }}%
                                <span class="counts">{{ $tc('interface.participants', data.counts.engagement) }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="matrix-outline text-left">&larr; Risicovol Inzetbaar </td>
                            <td class="matrix-outline"></td>
                            <td class="matrix-outline text-right">Inzetbaar &rarr;</td>
                        </tr>
                        <tr>
                            <td class="square">
                                {{ (data.percentages['risk'] * 100).toFixed(0) }}%
                                <span class="counts">{{ $tc('interface.participants', data.counts.risk) }}</span>
                            </td>
                            <td class="matrix-outline text-vertical-bottom">Minder Bevlogen &rarr;</td>
                            <td class="square">
                                {{ (data.percentages['none'] * 100).toFixed(0) }}%
                                <span class="counts">{{ $tc('interface.participants', data.counts.none) }}</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { NolostInsights } from '@/mixins/NolostInsights'

export default {
    name: "Matrix",
    mixins: [NolostInsights],
    props: ['content'],
    data() {
        return {
            error: false,
            loading: true,
            data: [],
            total_count: undefined,
        }
    },
    computed: {
        currentCompares () { return this.$store.state.currentCompares },
        currentFilters () { return this.$store.state.currentFilters },
        currentBenchmarks () { return this.$store.state.currentBenchmarks },
    },
    methods: {
        getData() {
            // Question codes to make the correlation with
            this.loading = true;
            this.axios.get(process.env.VUE_APP_API_URL + '/insights/matrix/')
            .then(resp => {
                this.data = resp.data.data
                this.loading = false;
                this.total_count = this.data.counts['none'] + this.data.counts['risk'] + this.data.counts['engagement'] + this.data.counts['risk_and_engagement']
            })
            .catch(err => {
                this.error = err
                this.questions = []
                this.loading = false;
            })
        },
    },
    mounted() {
        this.getData()
    }
}
</script>

<style>
    table.table-matrix{
        padding: 20px;
        margin: 0px auto;
        border-spacing: 0px;
        border-color: #0F265C;
    }
    table.table-matrix tr td{
        border: 0px solid;
        border-spacing: 0px;

    }
    table.table-matrix tr td span{
        display: block;
        font-size: 14px;
    }
    .matrix-outline{
        background-color: #0F265C;
        color: white;
        padding: 10px
    }
    .text-left{
        text-align: left;
    }
    .text-right{
        text-align: right;
    }
    .text-vertical-top{
        writing-mode: vertical-rl;
        text-align: top;
    }
    .text-vertical-bottom{
        writing-mode: vertical-rl;
        text-align: right;
    }
    .square{
        width: 200px;
        height: 200px;
        /* background-color: #BFE6F5; */
        font-size: 3rem;
        text-align: center;
        color: #0F265C;
    }
    .counts{
        padding-top: 20px;
    }
</style>